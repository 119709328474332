<template>
  <div v-if="!isTokenLogin">
    <div class="my-5">
      <vc-app-title size="xl" center />
    </div>
    <div class="platter mx-auto px-4 px-sm-5">
      <template v-if="showNoAccess">
        <h4 class="mb-4">
          <strong>
            Your account doesn't have access
          </strong>
        </h4>

        <div
          v-if="$store.state.metadata"
          v-html="$store.state.metadata.noAccessHtml"
        ></div>
      </template>
      <template v-else>
        <b-alert v-if="$route.query.reset" variant="success" :show="true"
          >Your new password has been set.</b-alert
        >

        <p v-if="$store.state.metadata">
          <strong>
            {{ $store.state.metadata.logInMsg }}
          </strong>
        </p>
        <b-form @submit="logIn">
          <b-form-group title="Username" label="Username">
            <b-input v-model="username" autocomplete="username" />
          </b-form-group>
          <b-form-group title="Password" label="Password">
            <b-input
              v-model="password"
              type="password"
              autocomplete="current-password"
              ref="pass"
            />
          </b-form-group>

          <b-check
            v-model="persist"
            switch
            :disabled="isLoggingIn"
            class="mb-3"
          >
            Keep Me Signed In On This Device<br />
            <transition name="fade">
              <small class="text-muted font-weight-bolder" v-if="persist"
                >Don't use on public or shared devices</small
              >
            </transition>
          </b-check>

          <b-button
            block
            variant="primary"
            class="mb-2"
            type="submit"
            :disabled="isLoggingIn"
          >
            <strong>
              Log In
            </strong>
          </b-button>
        </b-form>

        <b-button
          href="https://www1.plasticsurgery.org/ebusiness4/sso/login.aspx?do=reset"
          variant="link"
          block
          class="text-muted"
        >
          <u>Forgot Password</u>
        </b-button>

        <b-alert variant="danger" :show="!!errorMessage" class="mt-2">
          {{ errorMessage }}
        </b-alert>
      </template>
    </div>
  </div>
  <div v-else class="p-5">
    <vc-loading>Loading...</vc-loading>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      username: undefined,
      password: undefined,
      isLoggingIn: false,
      errorMessage: undefined,
      showNoAccess: false,
      persist: false,
      isTokenLogin: false
    };
  },
  computed: {
    startingRoute() {
      const startingRoute = this.$store.state.startingRoute;
      return startingRoute?.meta?.requiresAuth ? startingRoute : "/";
    },
    credentials() {
      return {
        username: this.username,
        password: this.password,
        persist: this.persist
      };
    }
  },
  watch: {
    isLoggingIn() {
      if (this.isLoggingIn) {
        this.errorMessage = undefined;
      } else {
        this.isTokenLogin = false;
      }
    }
  },
  mounted() {
    if (this.logOut) this.$store.commit("setLogIn", undefined);
    else if (this.$store.state.authToken) {
      this.isTokenLogin = true;
      this.logIn();
    }

    this.username = this.$route.query.user;

    if (this.username) this.$refs.pass.focus();
  },
  methods: {
    logIn(evt) {
      evt?.preventDefault();
      this.isLoggingIn = true;

      this.$store.dispatch("logIn", this.credentials).then(
        d => {
          if (!d.canAccess) {
            this.showNoAccess = true;
            return;
          }
          this.$router.replace(this.startingRoute);
          this.$store.commit("setStartingRoute", undefined);
        },
        d => {
          this.errorMessage = d.message;
          this.isLoggingIn = false;
        }
      );
    }
  },
  props: {
    logOut: { type: Boolean }
  }
};
</script>

<style scoped lang="scss">
.platter {
  max-width: 450px;
}
</style>